<template>
  <div class="terms" v-if="page">
    <div class="outer-container">
      <div class="inner-container">
        <div class="terms-content">
          <h2>Disclaimer</h2>
          This information contained on this website is presented in good faith and on the basis that Wine Australia, nor their agents or employees, are liable (whether by reason of error, omission, negligence, lack of care or otherwise) to any person for any damage or loss whatsoever which has occurred or may occur in relation to that person taking or not taking (as the case may be) action in respect of any statement, information or advice given in this website.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Analytics from '@/analytics'
import Kontent from "@/kontent";

export default {
  name: 'Privacy',
  props: {},
  data() {
    return {
      page: null
    }
  },
  mounted() {
    Analytics.trackPage('Privacy')
    Kontent.getItemByType('privacy_page').then((res) => {
      this.page = res.data.items[0]

      setTimeout(function(){window.prerenderReady = true}, 1000)
    })
  }
}
</script>

<style lang="scss">
.terms-content {
  margin-top: 30px;
  margin-bottom: 50px;
  color: #FFF;

  table {
    border: none!important;
    border-top: 1px solid #eceae5!important;
    border-left: 1px solid #eceae5!important;
  }

  table td, table th {
    border: none!important;
    border-bottom: 1px solid #eceae5!important;
    border-right: 1px solid #eceae5!important;
    padding: 0 10px;
  }

  h1, h2, h3, h4, h5 {
    margin-bottom: 20px;
    margin-top: 40px;
    font-weight: bold;
  }

  p {
    margin: 15px 0;
  }

  a {
    text-decoration: underline;
  }

  ul {
    margin: 20px 0;
    padding-left: 25px;

    > li {
      list-style-type: disc;
    }
  }

  ol {
    margin: 20px 0;
    padding-left: 25px;

    > li {
      list-style-type: decimal;
    }
  }
}
</style>
